import React from 'react';
//import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
//import data from "./data.json";
/*import Modal from "../modal/Modal";
import Photo1 from '../../assets/photo-01.png';
import Photo2 from '../../assets/photo-02.png';
import Photo3 from '../../assets/photo-03.png';
import Photo4 from '../../assets/photo-04.png';
import Photo5 from '../../assets/photo-05.png';
import Photo6 from '../../assets/photo-06.png';
import Photo7 from '../../assets/photo-07.png';
import Photo8 from '../../assets/photo-08.png';
import Photo9 from '../../assets/photo-09.png';*/
import LogoGis from '../../assets/logo-gis.svg';
import LogoGisBlack from '../../assets/logo-gis-black.svg';
import './gallery.css';

export const Gallery = () => {
  /*const [clickedImg, setClickedImg ] = useState(null);
  const [clickedImgAlt, setClickedImgAlt ] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (photo, index) => {
      setCurrentIndex(index);
      setClickedImg(photo.image);
      setClickedImgAlt(photo.imageAlt);
      const blurring = document.querySelectorAll('.blurrable');
      document.body.style.overflow = "hidden";
      for (const blur of blurring) {
          blur.classList.add('blur-effect');
      }
  };

  const handlePrev = () => {
      const totalLength = data.data.length;
      if(currentIndex === 0) {
          setCurrentIndex(totalLength - 1);
          const newUrlImg = data.data[totalLength - 1].image;
          const newUrlImgAlt = data.data[totalLength - 1].imageAlt;
          setClickedImg(newUrlImg);
          setClickedImgAlt(newUrlImgAlt);
          return;
      }
      const newIndex = currentIndex - 1;
      const newUrl = data.data.filter((photo) => {
          return data.data.indexOf(photo) === newIndex;
      });
      const newItemImg = newUrl[0].image;
      const newItemImgAlt = newUrl[0].imageAlt;
      setClickedImg(newItemImg);
      setClickedImgAlt(newItemImgAlt);
      setCurrentIndex(newIndex);
  }

  const handleNext = () => {
      const totalLength = data.data.length;
      if(currentIndex + 1 >= totalLength) {
          setCurrentIndex(0);
          const newUrlImg = data.data[0].image;
          const newUrlImgAlt = data.data[0].imageAlt;
          setClickedImg(newUrlImg);
          setClickedImgAlt(newUrlImgAlt);
          return;
      }
      const newIndex = currentIndex + 1;
      const newUrl = data.data.filter((photo) => {
          return data.data.indexOf(photo) === newIndex;
      });
      const newItemImg = newUrl[0].image;
      const newItemImgAlt = newUrl[0].imageAlt;
      setClickedImg(newItemImg);
      setClickedImgAlt(newItemImgAlt);
      setCurrentIndex(newIndex);
  };*/
  return (
    <div className="gis__gallery">
        <span className="anchor-gallery" id="gis_gallery" />
        <div className="pseudoserver">
          {/*<img src={Photo1} alt="Photo-1" />
          <img src={Photo2} alt="Photo-2" />
          <img src={Photo3} alt="Photo-3" />
          <img src={Photo4} alt="Photo-4" />
          <img src={Photo5} alt="Photo-5" />
          <img src={Photo6} alt="Photo-6" />
          <img src={Photo7} alt="Photo-7" />
          <img src={Photo8} alt="Photo-8" />
          <img src={Photo9} alt="Photo-9" />*/}
          <img src={LogoGis} alt="LogoGIS" />
          <img src={LogoGisBlack} alt="LogoGISblack" />
        </div>
        <div className="gis__gallery-heading blurrable">
            <div className="gis__gallery-heading-pin"><p><FontAwesomeIcon icon={faImages}></FontAwesomeIcon></p></div>
            <h1 className="gis__gallery-heading-text gradient__text">Galeria</h1>
        </div>
        <div className="gis__gallery-panel">
          <div className="gis__gallery-panel-container blurrable">
            <div className="gallery-photo pos">
            <iframe width="560" height="381" src="https://www.youtube.com/embed/NQxrBLxh_Es" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>
            </div>
          </div>
          <div className="gis__gallery-panel-container blurrable">
            <div className="gallery-photo pos">
            <iframe width="560" height="381" src="https://www.youtube.com/embed/Mm44XqvU-MI?si=7T11czfuDiCE4Zrx" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>
            </div>
          </div>
          <div className="gis__gallery-panel-container blurrable">
            <div className="gallery-photo pos">
            <iframe width="560" height="381" src="https://www.youtube.com/embed/d2AD4bgoDsA?si=KDetyDRlbtYjpv1t" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>
            </div>
          </div>
          <div className="gis__gallery-panel-container blurrable">
            <div className="gallery-photo pos">
            <iframe width="560" height="381" src="https://www.youtube.com/embed/VLhY4RFPZKs?si=Ul8u1fNo7DShCw7k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>
            </div>
          </div>
          <div className="gis__gallery-panel-container blurrable">
            <div className="gallery-photo pos">
            <iframe width="560" height="381" src="https://www.youtube.com/embed/NaxNhylo8oM?si=LwZ0JzqJnjZBABrY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>
            </div>
          </div>
          <div className="gis__gallery-panel-container blurrable">
            <div className="gallery-photo pos">
            <iframe width="560" height="381" src="https://www.youtube.com/embed/0r1pIo41hQE?si=pwVRqQwVM4Wsk04p" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>
            </div>
          </div>
          {/*{data.data.map((photo, index) => (
            <div key={index} onClick={() => handleClick(photo, index)} className="gis__gallery-panel-container blurrable">
              <div className="gallery-photo pos">
                <img src={photo.image} alt={photo.imageAlt} />
                <div className={"gallery-photo-more gallery-image-" + index}>
                  <h2>+10 WIĘCEJ</h2>
                </div>
              </div>
            </div>
          ))}
          { clickedImg && (
            <Modal 
              clickedImg={clickedImg}
              clickedImgAlt={clickedImgAlt}
              handlePrev={handlePrev}
              handleNext={handleNext}
              setClickedImg={setClickedImg}
              setClickedImgAlt={setClickedImgAlt}
            />
          )}*/}
        </div>
    </div>
  )
}

export default Gallery