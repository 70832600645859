import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import GisPhoto from '../../assets/gisphoto.webp';
import { useCountUp } from 'react-countup';
import './about.css';

const About = () => {
    useCountUp({
        ref: 'counter-price',
        end: 5200,
        duration: 2,
        enableScrollSpy: true,
        scrollSpyDelay: 500,
        scrollSpyOnce: true,
    });
    useCountUp({
        ref: 'counter-years',
        end: 1.0,
        decimal: ',',
        decimals: 1,
        duration: 2,
        enableScrollSpy: true,
        scrollSpyDelay: 500,
        scrollSpyOnce: true,
    })
  return (
    <div className="gis__about blurrable">
        <span className="anchor-about" id="gis_about" />
        <div className="gis__about-heading">
            <div className="gis__about-heading-pin"><p><FontAwesomeIcon icon={faGraduationCap}></FontAwesomeIcon></p></div>
            <h1 className="gis__about-heading-text gradient__text">O kierunku</h1>
        </div>
        <div className="gis__about-container">
            <div className="gis__about-container_image">
                <img src={GisPhoto} alt="Gisphoto"/>
            </div>
            <div className="gis__about-container_description">
                <div className="description__top">
                    <h1>Profil kierunku</h1>
                    <p>Studia podyplomowe GIS skierowane są do osób chcących pogłębić i usystematyzować swoją wiedzę z zakresu systemów informacji geograficznej. Studia obejmują zagadnienia podstawowe jak: pozyskiwanie i konwersja danych, analiza danych wektorowych i rastrowych, wizualizacja danych, oraz zagadnienia zaawansowane jak: tworzenie własnych skryptów w Python, geostatystyka, analizy sieciowe, obsługa danych LiDAR, przetwarzanie danych z drona. Najcenniejszym zasobem studiów jest wiedza przekazywana przez osoby pracujące na co dzień z danymi przestrzennymi.</p>
                    <h2>Dlaczego warto?</h2>
                    <p>Podczas studiów zapoznasz się z najnowszymi metodami pomiarowymi GNSS, LiDAR czy pozyskiwanie danych z drona. Nauczysz się obsługi danych przestrzennych w środowisku ArcGIS Pro, QGIS i za pomocą Python. Poznasz nowoczesne metody prezentacji danych przestrzennych jak i danych pochodzących z baz danych.</p>
                    <h3>Formuła studiów</h3>
                    <p>Zajęcia prowadzone są w formie warsztatów i ćwiczeń w kontakcie, wykłady głównie online. Zjazdy planowane są co 2 tygodnie z uwzględnieniem przerw świątecznych. Podczas studiów odbędzie się kilka zajęć terenowych w obrębie kampusu Politechniki Gdańskiej.</p>
                </div>
                <div className="description__bottom">
                    <div className="description__bottom-time">
                        <p>Czas trwania</p>
                        <h4><span id='counter-years' />&nbsp;rok</h4>
                    </div>
                    <div className="description__bottom-price">
                        <p>Koszt studiów</p>
                        <div className="price__counter">
                        <h4><span id='counter-price' />&nbsp;PLN</h4>
                        </div>
                    </div>
                    <div className="description__bottom-register">
                        <p>Zarejestruj się na studia</p>
                        <a href="https://rekrutacja.pg.edu.pl/" target="_blank" rel="noreferrer"><button>Rekrutacja</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About