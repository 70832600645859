import React from 'react';
import { useEffect } from 'react';
import { Navbar, Header, Main, Footer, Sm, Register, More } from './containers';
import { Backtop, CookiesBanner } from './components';
import './App.css';

const App = () => {
    const handleScroll = () => {
        const position = window.pageYOffset;
        if(position > 0) {
          document.getElementsByClassName("gis__navbar")[0].classList.add("scroll-nav");
          document.getElementsByClassName("gis__navbar-links_container")[0].classList.add("scroll-btn");
          document.getElementsByClassName("gis__backtop")[0].classList.add("backtop-vis");
          document.getElementById("gis-logo").src="http://gisnapg.pl/static/media/logo-gis-black.962fb2c0218a89a30538e774e7e0d284.svg";
        }
        else {
          document.getElementsByClassName("gis__navbar")[0].classList.remove("scroll-nav");
          document.getElementsByClassName("gis__navbar-links_container")[0].classList.remove("scroll-btn");
          document.getElementsByClassName("gis__backtop")[0].classList.remove("backtop-vis");
          document.getElementById("gis-logo").src="http://gisnapg.pl/static/media/logo-gis.410dbc37b5b06a875d6aec3a4080e146.svg";
        }
      };
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    return (
        <div className="App">
            <Navbar />
            <Sm />
            <Register />
            <More />
            <Backtop />
            <Header />
            <Main />
            <Footer />
            <CookiesBanner />
        </div>
    )
}

export default App